const React = require('react')

import Amplify from 'aws-amplify'

import AuthContextProvider from './context/AuthContextProvider'

const remember_me =
  typeof window !== 'undefined' &&
  localStorage.getItem('remember_me') === 'true'

Amplify.configure({
  storage: remember_me ? localStorage : sessionStorage,

  aws_project_region: process.env.GATSBY_AWS_REGION,
  aws_cognito_region: process.env.GATSBY_AWS_REGION,
  aws_user_pools_id: process.env.GATSBY_AWS_COGNITO_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.GATSBY_AWS_COGNITO_USER_POOLS_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.GATSBY_AWS_COGNITO_AUTH_DOMAIN,
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: process.env.GATSBY_AWS_COGNITO_REDIRECT_SIGNIN,
    redirectSignOut: process.env.GATSBY_AWS_COGNITO_REDIRECT_SIGNOUT,
    responseType: process.env.GATSBY_AWS_COGNITO_RESPONSE_TYPE,
  },
})

const AuthWrapper = (props) => (
  <AuthContextProvider>{props.children}</AuthContextProvider>
)

export default ({ element }) => <AuthWrapper>{element}</AuthWrapper>
