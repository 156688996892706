// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-auth-forgot-password-confirmation-js": () => import("./../../../src/pages/auth/forgot-password-confirmation.js" /* webpackChunkName: "component---src-pages-auth-forgot-password-confirmation-js" */),
  "component---src-pages-auth-forgot-password-js": () => import("./../../../src/pages/auth/forgot-password.js" /* webpackChunkName: "component---src-pages-auth-forgot-password-js" */),
  "component---src-pages-auth-login-js": () => import("./../../../src/pages/auth/login.js" /* webpackChunkName: "component---src-pages-auth-login-js" */),
  "component---src-pages-discover-products-js": () => import("./../../../src/pages/discover-products.js" /* webpackChunkName: "component---src-pages-discover-products-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-onboarding-js": () => import("./../../../src/pages/onboarding.js" /* webpackChunkName: "component---src-pages-onboarding-js" */),
  "component---src-templates-contentful-page-js": () => import("./../../../src/templates/ContentfulPage.js" /* webpackChunkName: "component---src-templates-contentful-page-js" */)
}

