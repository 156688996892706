import React from 'react'

export const STORAGE_AT_KEY = 'hybridStorage_at'
export const STORAGE_DATA_KEY = 'hybridStorage_data'
export const STORAGE_REQUEST_KEY = 'hybridStorage_request'
export const STORAGE_RELOAD_KEY = 'hybridStorage_windowreload'
export const STORAGE_TTL = 12 * 60 * 60 * 1000 // this also becomes session expiry

const isBrowser = typeof window !== 'undefined'

export const hybridStorage = {
  STORAGE_AT_KEY: STORAGE_AT_KEY,

  broadcast: (atTime = Date.now()) => {
    if (!isBrowser) return

    sessionStorage.setItem(STORAGE_AT_KEY, atTime)
    localStorage.setItem(STORAGE_AT_KEY, atTime)
    localStorage.setItem(
      STORAGE_DATA_KEY,
      JSON.stringify(window.sessionStorage)
    )
    localStorage.removeItem(STORAGE_DATA_KEY, localStorage)
  },

  request: (atTime = Date.now()) => {
    if (!isBrowser) return

    localStorage.setItem(STORAGE_REQUEST_KEY, atTime)
    localStorage.removeItem(STORAGE_REQUEST_KEY)
  },

  forceOthersToReload: (atTime = Date.now()) => {
    if (!isBrowser) return

    localStorage.setItem(STORAGE_RELOAD_KEY, atTime)
    localStorage.removeItem(STORAGE_RELOAD_KEY)
  },

  key: (i) => {
    if (!isBrowser) return

    sessionStorage.key(i)
  },

  getItem: (key) => {
    if (!isBrowser) return

    return sessionStorage.getItem(key)
  },

  setItem: (key, value) => {
    if (!isBrowser) return

    sessionStorage.setItem(key, value)
    hybridStorage.broadcast()
  },

  removeItem: (key) => {
    if (!isBrowser) return

    sessionStorage.removeItem(key)
    hybridStorage.broadcast()
  },

  // broadcasts a clear across all tabs
  clear: () => {
    if (!isBrowser) return

    sessionStorage.clear()
    hybridStorage.broadcast()
  },

  // making it compatible w/ amplify storage
  // https://docs.amplify.aws/lib/auth/manageusers/q/platform/js#managing-security-tokens
  syncPromise: null,
  sync: async () => {
    if (!hybridStorage.syncPromise) {
      hybridStorage.syncPromise = new Promise((res, rej) => {
        res()
      })
    }
    return hybridStorage.syncPromise
  },

  handlePageSync: () => {
    if (!isBrowser) return

    const now = Date.now()

    // todo lock this to only run once
    window.addEventListener('storage', hybridStorage._handleEvent)

    let hybridStorage_localAt = localStorage.getItem(STORAGE_AT_KEY) || 0
    if (
      hybridStorage_localAt &&
      (now - hybridStorage_localAt > STORAGE_TTL || now < hybridStorage_localAt)
    ) {
      // console.log('local too old')
      localStorage.removeItem(STORAGE_AT_KEY)
      sessionStorage.clear()
      hybridStorage_localAt = 0
    }

    if (hybridStorage_localAt) {
      // console.log('>local ' + hybridStorage_localAt)
      let hybridStorage_sessionAt = sessionStorage.getItem(STORAGE_AT_KEY) || 0
      //  console.log('>session ' + hybridStorage_sessionAt)
      //  console.log('>diff ' + (hybridStorage_localAt - hybridStorage_sessionAt))
      if (
        hybridStorage_sessionAt &&
        (now - hybridStorage_sessionAt > STORAGE_TTL ||
          hybridStorage_localAt > hybridStorage_sessionAt ||
          now < hybridStorage_sessionAt)
      ) {
        // console.log('session too old')
        sessionStorage.clear()
        hybridStorage_sessionAt = 0
      }

      if (
        sessionStorage.length === 0 ||
        hybridStorage_localAt > hybridStorage_sessionAt
      ) {
        // i have no session info perhaps my first go around
        hybridStorage.request()

        setTimeout(() => {
          // if i don't get a response assume all other tabs are dead
          hybridStorage_sessionAt = sessionStorage.getItem(STORAGE_AT_KEY) || 0
          if (!hybridStorage_sessionAt) {
            // console.log("giving up on reloading")
            localStorage.removeItem(STORAGE_AT_KEY)
          }

          window.location.reload(false)
        }, 300)

        // todo make me pretty, but not too pretty
        return <h1>Loading...</h1>
      }
    }

    return null
  },

  _handleEvent: (event) => {
    if (event.key === STORAGE_REQUEST_KEY && sessionStorage.length) {
      const data = JSON.stringify(window.sessionStorage)
      // console.log('hybridStorage sending ' + data)

      localStorage.setItem(STORAGE_DATA_KEY, data)
      localStorage.removeItem(STORAGE_DATA_KEY)
    }

    if (event.key === STORAGE_DATA_KEY) {
      const newValue = event.newValue
      if (!newValue) {
        // catches removeItem
        return
      }
      const parsedValue = JSON.parse(newValue)

      if (
        parsedValue[STORAGE_AT_KEY] <= sessionStorage.getItem(STORAGE_AT_KEY)
      ) {
        // console.log('hybridStorage received too old')
        return
      }
      // console.log('hybridStorage received ' + newValue)

      sessionStorage.clear()
      Object.entries(parsedValue).forEach((entry) => {
        const [key, value] = entry
        sessionStorage.setItem(key, value)
      })
    }

    if (event.key === STORAGE_RELOAD_KEY) {
      const reloadRequestTime = event.newValue
      if (!reloadRequestTime) {
        // catches removeItem
        return
      }
      if (reloadRequestTime <= sessionStorage.getItem(STORAGE_AT_KEY)) {
        return
      }

      setTimeout(() => {
        // gives it enough time for STORAGE_DATA_KEY to arrive
        window.location.reload(false)
      }, 500)
    }
  },
}

Object.defineProperty(hybridStorage, 'length', {
  get: () => {
    if (!isBrowser) return
    return sessionStorage.length
  },
  enumerable: true,
})

export default hybridStorage
