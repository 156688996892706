import Amplify, { Auth } from 'aws-amplify'
import axios from 'axios'

const isBrowser = () => typeof window !== 'undefined'

const getStorage = () =>
  !isBrowser()
    ? false
    : localStorage.getItem('remember_me') === 'true'
    ? localStorage
    : sessionStorage

const USER_KEY = 'floof_user'
const USERNAME_KEY = 'floof_username'
const REDIRECT_PATH_KEY = 'floof_redirect_path'

const setUser = (user) => {
  const tmpUser = JSON.parse(JSON.stringify(user))

  // the contents of localStorage is in user -- we don't need it
  delete tmpUser.storage

  isBrowser() && getStorage().setItem(USER_KEY, JSON.stringify(tmpUser))

  isBrowser() && getStorage().setItem(USERNAME_KEY, tmpUser.username || '')
}

export const getUsername = () => {
  // todo change this to whichever storage amplify is using
  if (isBrowser() && getStorage().getItem(USERNAME_KEY)) {
    return isBrowser() && getStorage().getItem(USERNAME_KEY)
  }
}

export const getUser = async () => {
  if (isBrowser() && getStorage().getItem(USER_KEY)) {
    // todo match result with getUsername
    return JSON.parse(getStorage().getItem(USER_KEY))
  }

  // fallback, if user is not found in sessionStorage try to find it in Amplify
  const user = await Auth.currentAuthenticatedUser()
  if (user && user.username) {
    // console.log('fallback getUser ' + JSON.stringify(user))
    setUser(user)
    return user
  }

  return {}
}

export const getRedirectPath = () =>
  isBrowser() && window.sessionStorage.getItem(REDIRECT_PATH_KEY)
    ? window.sessionStorage.getItem(REDIRECT_PATH_KEY)
    : null

export const setRedirectPath = (path) =>
  isBrowser() && window.sessionStorage.setItem(REDIRECT_PATH_KEY, path)

export const removeRedirectPath = () =>
  isBrowser() && window.sessionStorage.removeItem(REDIRECT_PATH_KEY)

export const signUp = async ({
  firstName,
  lastName,
  email,
  password,
  validationData,
}) => {
  // TODO: Eventually, the back end will accept a separate first and last name
  const name = `${firstName} ${lastName}`

  const result = await Auth.signUp({
    username: email,
    password,
    attributes: {
      given_name: firstName,
      family_name: lastName,
      name,
      email,
    },
    validationData,
  })
  const user = await Auth.signIn(email, password)

  setUser(user)

  // TODO: This may not be necessary
  return user
}

export const login = async ({ email, password, rememberMe = false }) => {
  if (!isBrowser()) {
    return {}
  }

  localStorage.setItem('remember_me', rememberMe)

  Amplify.configure({
    storage: getStorage(),
  })

  const user = await Auth.signIn(email, password)

  setUser(user)

  return user
}

export const forgotPassword = async ({ email }) => {
  await Auth.forgotPassword(email)
}

export const confirmForgotPassword = async ({
  email,
  verificationCode,
  password,
}) => {
  await Auth.forgotPasswordSubmit(email, verificationCode, password)
}

export const isLoggedIn = () => {
  const username = getUsername()

  // todo take into account session expiry

  return !!username
}

export const getSession = async () => await Auth.currentSession()

export const getAccessToken = async () => {
  try {
    await getSession() // this will refresh the token if needed
  } catch {
    // could not get current session
    logout()
    isBrowser() && window.location.reload(false)
  }

  const authUser = await getUser()

  const accessToken =
    isBrowser() &&
    getStorage().getItem(
      `${authUser.keyPrefix}.${authUser.username}.accessToken`
    )

  // todo, null checks.

  return accessToken
}

export const logout = (
  callback = () => {
    /* NOOP */
  }
) => {
  setUser({})

  callback()
}
