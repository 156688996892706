import React, { useReducer, createContext } from 'react'

import { isLoggedIn } from '../services/auth'
import {
  logSigninUser as zootopiaLogSignin,
  clearCacheUserInfo,
} from '../services/zootopia'
import hybridStorage from '../services/hybridStorage'

export const AuthStateContext = createContext({ loggedIn: false })
export const AuthDispatchContext = createContext()

export const LOG_IN = 'LOG_IN'
export const LOG_OUT = 'LOG_OUT'

const loggedIn = isLoggedIn()

const initialState = {
  loggedIn,
}

const reducer = (state, action) => {
  const now = Date.now()
  switch (action.type) {
    case LOG_IN:
      zootopiaLogSignin()
      hybridStorage.forceOthersToReload(now - 1)
      hybridStorage.broadcast()

      return {
        ...state,
        loggedIn: true,
      }
    case LOG_OUT:
      // clears localStorage (most of it)
      // todo make me better
      Object.keys(localStorage)
        .filter((k) => {
          return (
            k.startsWith('CognitoIdentityServiceProvider') ||
            k.startsWith('floof')
          )
        })
        .map((k) => localStorage.removeItem(k))
      Object.keys(sessionStorage)
        .filter((k) => {
          return (
            k.startsWith('CognitoIdentityServiceProvider') ||
            k.startsWith('floof')
          )
        })
        .map((k) => sessionStorage.removeItem(k))

      clearCacheUserInfo()

      hybridStorage.forceOthersToReload(now - 1)
      hybridStorage.broadcast()

      return {
        ...state,
        loggedIn: false,
      }
    default:
      throw new Error('Invalid action')
  }
}

const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

export default AuthContextProvider
