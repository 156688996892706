import zlib, { deflateSync, inflateSync } from 'zlib'
import MD5 from 'md5.js'

import hybridStorage from '../services/hybridStorage'
import {
  getAccessToken,
  getSession as getAuthSession,
  getUser as getAuthUser,
  getUsername as getAuthUsername,
} from '../services/auth'

const axios = require('axios').default.create({
  mode: 'no-cors',
  crossdomain: true,
  headers: {
    'Content-Type': 'application/json',
  },
})

axios.interceptors.request.use(async (config) => {
  // adds in Authorization when required
  if (config.accessToken) {
    const accessToken = await getAccessToken()
    config.headers.Authorization = `Bearer ${accessToken}`
  }

  return config
})

const API_BASE = process.env.GATSBY_ZOOTOPIA_API_BASE_PATH // TODO MOVE UP TO CONFIG
const CACHE_TTL = 4 * 60 * 60 * 1000
const SOURCE = 'FLOOF' // todo constants or env

const STORAGE_PREFIX = {
  BREED: 'breed_a',
  PET: 'pets_a',
  USER: 'user_a',
}

export const storageDecode = (s) => {
  return JSON.parse(zlib.inflateSync(new Buffer('eJ' + s, 'base64')).toString())
}

export const storageEncode = (o) => {
  return zlib
    .deflateSync(JSON.stringify(o))
    .toString('base64')
    .replace(/^eJ/, '')
    .replace(/=/, '')
}

/*
// in case we have to revert to non-compressed values
export const storageEncode = (o) => { return JSON.stringify(o) }
export const storageDecode = (s) => { return JSON.parse(s) }
*/

/**
 * genericCatch
 * - general purpose logging catch
 * - and rethow
 */
const genericCatch = async (err) => {
  if (err.response) {
    if (
      err.response.status === 401 &&
      err.response.data.detail === 'jwt expired'
    ) {
      console.log('jwt expired')
      await getAuthSession()
      // todo refresh jwt token

      // how do i force a retry?!
    }

    // client received an error response (5xx, 4xx)
    // console.log(err.response.data);
    console.error(err.response.data)
    throw err
  } else if (err.request) {
    // client never received a response, or request never left
    throw err
  } else {
    // anything else
    throw err
  }
}

// ---------------

export const clearCacheUserInfo = (sessionOnly = false) => {
  if (!sessionOnly) {
    Object.keys(localStorage)
      .filter((k) => {
        return k.startsWith('onboarding_')
      })
      .map((k) => localStorage.removeItem(k))
  }

  Object.keys(sessionStorage)
    .filter((k) => {
      return (
        k.startsWith('user_') ||
        k.startsWith('pets_') ||
        k.startsWith('onboarding_')
      )
    })
    .map((k) => sessionStorage.removeItem(k))
}

export const preCacheUserInfo = async () => {
  const userSub = getAuthUsername()

  // todo refine this.
  let cacheTime = sessionStorage.getItem(hybridStorage.STORAGE_AT_KEY)
  const localStorageTime = localStorage.getItem(hybridStorage.STORAGE_AT_KEY)
  let refreshedAt = Date.now()

  if (localStorageTime && localStorageTime > cacheTime) {
    // another tab update this, let's fix the information on this tab
    refreshedAt = localStorageTime
    cacheTime = false
  }

  if (cacheTime && Date.now() - cacheTime < CACHE_TTL) {
    // console.log('precache hit')
    return
  }
  // console.log('precache miss')

  // clears session only
  clearCacheUserInfo(true)

  const [zootopiaUser, zootopiaPets] = await Promise.all([
    getUser(userSub),
    getPets(userSub),
  ])

  if (zootopiaPets) {
    await zootopiaPets.map(async (p) => {
      await Promise.all([
        getBreed(p.wisdom_breed_id_1),
        getBreed(p.wisdom_breed_id_2),
        getBreed(p.wisdom_breed_id_3),
        getBreed(p.wisdom_breed_id_4),
      ])
    })
  }

  hybridStorage.broadcast(refreshedAt)
}

// ---------------

export const getBreedSuggest = async (s) => {
  const url = `${API_BASE}/breeds/_search?page=1&size=10&highlight=false&fieldset=default&name.suggest=${s}`
  const response = await axios.get(url).catch(genericCatch)

  return response.data.data || []
}

export const getBreedAll = async (s) => {
  const url = `${API_BASE}/breeds/_search?page=1&size=500&highlight=false&fieldset=default`
  const response = await axios.get(url).catch(genericCatch)

  return response.data.data || []
}

export const getBreed = async (breedId) => {
  const url = `${API_BASE}/breeds/_search?page=1&size=10&highlight=false&fieldset=all&wisdomId.equals=${breedId}`
  return _getBreed(breedId, url)
}

export const getBreedBySlug = async (slug) => {
  const url = `${API_BASE}/breeds/_search?page=1&size=10&highlight=false&fieldset=all&slug.equals=${slug}`
  return _getBreed(slug, url)
}

export const _getBreed = async (qs, url) => {
  if (!qs) return

  try {
    const breed = storageDecode(
      localStorage.getItem(`${STORAGE_PREFIX.BREED}_${qs}_data`)
    )
    if (breed) {
      return breed
    }
  } catch {
    // empty catch
  }

  const rt = await axios
    .get(url + `&cache=${STORAGE_PREFIX.BREED}`)
    .catch(genericCatch)

  try {
    const responseBreedData = rt.data.data[0]._source

    localStorage.setItem(
      `${STORAGE_PREFIX.BREED}_${responseBreedData.wisdomId}_data`,
      storageEncode(responseBreedData)
    )
    localStorage.setItem(
      `${STORAGE_PREFIX.BREED}_${responseBreedData.slug}_data`,
      storageEncode(responseBreedData)
    )

    return responseBreedData
  } catch {}
}

export const isUrbanOrRural = async (zip) => {
  const url = `${API_BASE}/location/isUrbanOrRural/${zip}`

  const rt = await axios.get(url).catch(genericCatch)

  return rt.data
}

/******* SEMI-SECURE STUFF *********/

export const startOnboarding = async (data = {}) => {
  const url = `${API_BASE}/floof/onboarding/start`

  const rt = await axios.post(url, JSON.stringify(data)).catch(genericCatch)

  return rt.data
}

export const completeOnboarding = async (uuid, pet_profile_id, data = {}) => {
  const url = `${API_BASE}/floof/onboarding/complete/${uuid}`

  const rt = await axios
    .put(
      url,
      { pet_profile_id: pet_profile_id, completedData: data },
      {
        accessToken: true,
      }
    )
    .catch(genericCatch)

  return rt.data
}

/******* SECURE STUFF *********/

export const getUser = async (userSub = false) => {
  const url = `${API_BASE}/user-profiles`

  if (!userSub) {
    userSub = getAuthUsername()
  }

  try {
    const user = storageDecode(
      hybridStorage.getItem(`${STORAGE_PREFIX.USER}_${userSub}_data`)
    )
    if (user) {
      return user
    }
  } catch {
    // empty catch
  }

  // clears old user keys
  clearCacheUserInfo(true)

  const response = await axios
    .get(url, {
      accessToken: true,
    })
    .catch(async (err) => {
      if (err.response) {
        if (err.response.status === 404) {
          console.error('Users Not Found, trying to recreate?!')
          const newUser = await recreateUser()
          return { data: newUser }
        }

        console.error(err.response)
        throw err
      } else if (err.request) {
        // client never received a response, or request never left
        throw err
      } else {
        // anything else
        throw err
      }
    })
    .catch(genericCatch)

  const user = response.data

  hybridStorage.setItem(
    `${STORAGE_PREFIX.USER}_${user.sub}_data`,
    storageEncode(user)
  )
  return user
}

const recreateUser = async (patchData = {}) => {
  const authUser = await getAuthUser()

  const userData = {
    first_name: authUser.attributes.given_name || '',
    last_name: authUser.attributes.family_name || '',
    email: authUser.attributes.email,
    // "birth_year": 0,
    // "birth_date": "string",
    // "gender": "M",
    // "street_1": "",
    // "street_2": "",
    // "city": "",
    // "state_province": "",
    // "postal_zip": "",
    // "country": "",
    home_phone: authUser.attributes.phone_number,
    // "mobile_phone": "string",
    // "work_phone": "string",
  }

  return await createUser({ ...userData, ...patchData })
}

// createUser and updateUser are now the same
export const createUser = async (data) => {
  return await updateUser(data);
}

export const updateUser = async (data) => {
  const url = `${API_BASE}/user-profiles`

  const response = await axios
    .patch(url, data, { accessToken: true })
    .catch(async (err) => {
      if (err.response) {
        console.error(err.response)
        throw err
      } else if (err.request) {
        // client never received a response, or request never left
        throw err
      } else {
        // anything else
        throw err
      }
    })
    .catch(genericCatch)

  const user = response.data

  hybridStorage.setItem(
    `${STORAGE_PREFIX.USER}_${user.sub}_data`,
    storageEncode(user)
  )
  return user
}

export const logSigninUser = async () => {
  const url = `${API_BASE}/sign-in-history`

  const data = {
    source: SOURCE,
  }

  const response = await axios
    .post(url, data, {
      accessToken: true,
    })
    .catch(async (err) => {
      if (err.response) {
        if (err.response.status === 404) {
          console.error('Users Not Found, trying to recreate?!')
          const newUser = await recreateUser()

          // retry
          signinUser()
          return
        }

        console.error(err.response)
        throw err
      } else if (err.request) {
        // client never received a response, or request never left
        throw err
      } else {
        // anything else
        throw err
      }
    })
    .catch(genericCatch)
}

export const getPets = async (userSub = false) => {
  const url = `${API_BASE}/pet-profiles`

  if (!userSub) {
    userSub = getAuthUsername()
  }

  try {
    const pets = storageDecode(
      hybridStorage.getItem(`${STORAGE_PREFIX.PET}_${userSub}_data`)
    )
    if (pets) {
      return pets
    }
  } catch {
    // empty catch
  }

  // clears old pets keys
  Object.keys(sessionStorage)
    .filter((k) => {
      return k.startsWith('pets_')
    })
    .map((k) => sessionStorage.removeItem(k))

  const response = await axios
    .get(url, { accessToken: true })
    .catch(genericCatch)
  const pets = response.data

  hybridStorage.setItem(
    `${STORAGE_PREFIX.PET}_${userSub}_data`,
    storageEncode(pets)
  )

  return pets
}

export const getPetsWithBreedInfo = async (userSub = false, pets = false) => {
  if (!pets) {
    pets = await getPets(userSub)
  }

  return appendPetsWithBreedInfo(pets)
}

export const appendPetsWithAdditionalInfo = async (pets = false) => {
  if (!pets || pets.length === 0) {
    return []
  }

  const petsWithBreedInfo = await Promise.all(
    pets.map(async (p) => {
      p.additional_info = await getPetAdditionalInfo(p.id)
      return p
    })
  )

  return petsWithBreedInfo
}

export const appendPetsWithBreedInfo = async (pets = false) => {
  if (!pets || pets.length == 0) {
    return []
  }

  const petsWithBreedInfo = await Promise.all(
    pets.map(async (p) => {
      const breeds = await Promise.all([
        getBreed(p.wisdom_breed_id_1),
        getBreed(p.wisdom_breed_id_2),
        getBreed(p.wisdom_breed_id_3),
        getBreed(p.wisdom_breed_id_4),
      ])
      p.breed_info = breeds.filter((breed) => {
        return typeof breed !== 'undefined'
      })

      return p
    })
  )

  return petsWithBreedInfo
}

export const appendPetsWithTodos = async (pets = false) => {
  if (!pets || pets.length == 0) {
    return []
  }

  const petsWithTodos = await Promise.all(
    pets.map(async (p) => {
      const todos = await getFloofTodos(p.id)
      p.todos = todos
      return p
    })
  )

  return petsWithTodos
}

export const createPet = async (data) => {
  const url = `${API_BASE}/pet-profiles`

  const userSub = getAuthUsername()

  const response = await axios
    .post(url, data, { accessToken: true })
    .catch(genericCatch)

  hybridStorage.removeItem(`${STORAGE_PREFIX.PET}_${userSub}_data`)

  return response.data
}

export const updatePet = async (petProfileId, data) => {
  const url = `${API_BASE}/pet-profiles`

  const userSub = getAuthUsername()

  data.id = parseInt(petProfileId) // forcing petProfileId
  const response = await axios
    .patch(url, data, { accessToken: true })
    .catch(genericCatch)

  hybridStorage.removeItem(`${STORAGE_PREFIX.PET}_${userSub}_data`)

  return response.data
}

export const getPetAdditionalInfo = async (petProfileId) => {
  const url = `${API_BASE}/pet-profiles/${petProfileId}/additional-info`

  const response = await axios
    .get(url, { accessToken: true })
    .catch(genericCatch)

  // makes into a map keyed by todoId
  const additionalInfo = response.data.reduce((ac, a) => ({ ...ac, [a.domain]: a }), {})

  return additionalInfo
}

export const updatePetAdditionalInfo = async (petProfileId, domain, data) => {
  const url = `${API_BASE}/pet-profiles/${petProfileId}/additional-info/${domain}`

  const response = await axios
    .put(url, { data }, { accessToken: true })
    .catch(genericCatch)

  return response.data
}

export const getFloofTodos = async (petProfileId) => {
  const url = `${API_BASE}/floof/pet-profiles/${petProfileId}/todo`

  const response = await axios
    .get(url, { accessToken: true })
    .catch(genericCatch)

  // makes into a map keyed by todoId
  const todos = response.data.reduce((ac, a) => ({ ...ac, [a.todo_id]: a }), {})

  return todos
}

export const putFloofTodo = async (
  petProfileId,
  todoId,
  workingData,
  status
) => {
  const url = `${API_BASE}/floof/pet-profiles/${petProfileId}/todo/${todoId}`
  const data = {
    data: workingData,
    status: status,
  }

  const response = await axios
    .put(url, data, { accessToken: true })
    .catch(genericCatch)

  return response.data
}

export const getVetInsightToken = async () => {
  const authUsername = getAuthUsername()

  if (!authUsername) {
    return await getVetInsightTokenAnon()
  }

  const url = `${API_BASE}/vetInsight/authToken`

  // this is fine for now, but when we add in multiple pets
  // and or modifiable user profile (email/name) we need to 
  // change the hashing method 
  const hash = new MD5().update(authUsername).digest('hex')

  const bearerToken = await axios
    .get(`${url}?u=${hash}`, { accessToken: true })
    .catch(genericCatch)

  return bearerToken.data.replace(/Bearer /, '')
}

export const getVetInsightTokenAnon = async () => {
  const url = `${API_BASE}/vetInsight/authTokenAnon`

  const bearerToken = await axios.get(url).catch(genericCatch)

  return bearerToken.data.replace(/Bearer /, '')
}
